import { render, staticRenderFns } from "./ContactsComponent.vue?vue&type=template&id=0045b05e&scoped=true"
import script from "./ContactsComponent.vue?vue&type=script&lang=js"
export * from "./ContactsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0045b05e",
  null
  
)

export default component.exports